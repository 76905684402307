body {
    min-height: 100vh;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;

    background-color: var(--bg-color);
    color: var(--body-color);
    transition: color 0.6s, background-color 0.6s;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, sans-serif;
    line-height: 1.5;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;

    --bg-color: #f3f7f9;
    --bg-content-color: #fff;
    --bg-code: #fffbf3;
    --body-color: #444;
    --title-color: #111;
    --link-color: #6b17e6;
    --border-color: rgba(0, 0, 0, 0.1);
    --space: 3.5rem;
    --content-width: 860px;
    --header-height: 80px;
    --radius: 5px;
}

body.darkMode {
    --bg-color: #0d2538;
    --bg-content-color: #0f2d44;
    --bg-code: rgba(0, 0, 0, 0.3);
    --border-color: hsla(0, 0%, 100%, 0.1);
    --body-color: #ced8de;
    --title-color: #fff;
    --link-color: #af9cef;
}

#root {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}
